var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{staticClass:"form-horizontal",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('card',[_c('h4',{staticClass:"card-title",attrs:{"slot":"header"},slot:"header"},[_vm._v(" Type Validation ")]),_c('div',[_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-sm-2 col-form-label"},[_vm._v("Requred Text")]),_c('div',{staticClass:"col-sm-7"},[_c('ValidationProvider',{attrs:{"name":"required","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed }){return [_c('fg-input',{attrs:{"type":"text","error":failed
                    ? 'The Required text field is required'
                    : null,"has-success":passed},model:{value:(_vm.required),callback:function ($$v) {_vm.required=$$v},expression:"required"}})]}}],null,true)})],1),_c('label',{staticClass:"col-sm-3 label-on-right"},[_c('code',[_vm._v("required=\"true\"")])])]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-sm-2 col-form-label"},[_vm._v("Email")]),_c('div',{staticClass:"col-sm-7"},[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed }){return [_c('fg-input',{attrs:{"type":"email","error":failed
                    ? 'The Email field is required'
                    : null,"has-success":passed},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1),_c('label',{staticClass:"col-sm-3 label-on-right"},[_c('code',[_vm._v("email=\"true\"")])])]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-sm-2 col-form-label"},[_vm._v("Number")]),_c('div',{staticClass:"col-sm-7"},[_c('ValidationProvider',{attrs:{"name":"number","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed }){return [_c('fg-input',{attrs:{"type":"number","error":failed
                    ? 'The Numeric field is required'
                    : null,"has-success":passed},model:{value:(_vm.number),callback:function ($$v) {_vm.number=$$v},expression:"number"}})]}}],null,true)})],1),_c('label',{staticClass:"col-sm-3 label-on-right"},[_c('code',[_vm._v("numeric=\"true\"")])])]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-sm-2 col-form-label"},[_vm._v("Url")]),_c('div',{staticClass:"col-sm-7"},[_c('ValidationProvider',{attrs:{"name":"url","rules":{
                required: true,
                regex: /(https?:\/\/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9])(:?\d*)\/?([a-z_\/0-9\-#.]*)\??([a-z_\/0-9\-#=&]*)/g,
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed }){return [_c('fg-input',{attrs:{"type":"url","error":failed
                    ? 'The Url field is required'
                    : null,"has-success":passed},model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}})]}}],null,true)})],1),_c('label',{staticClass:"col-sm-3 label-on-right"},[_c('code',[_vm._v("url=\"true\"")])])]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-sm-2 col-form-label"},[_vm._v("Equal To")]),_c('div',{staticClass:"col-sm-3"},[_c('ValidationProvider',{attrs:{"name":"equalTo","rules":"required","vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed }){return [_c('fg-input',{attrs:{"type":"text","error":failed
                    ? 'The EqualTo field is required'
                    : null,"placeholder":"Source field","has-success":passed},model:{value:(_vm.equalTo),callback:function ($$v) {_vm.equalTo=$$v},expression:"equalTo"}})]}}],null,true)})],1),_c('div',{staticClass:"col-sm-3"},[_c('ValidationProvider',{attrs:{"name":"equal","rules":"required|confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed }){return [_c('fg-input',{attrs:{"type":"text","error":failed
                    ? 'The EqualToSource does not match'
                    : null,"placeholder":"Destination field","has-success":passed},model:{value:(_vm.equal),callback:function ($$v) {_vm.equal=$$v},expression:"equal"}})]}}],null,true)})],1),_c('label',{staticClass:"col-sm-4 label-on-right"},[_c('code',[_vm._v("confirmed=\"equalToSource\"")])])])]),_c('div',{staticClass:"text-center"},[_c('n-button',{attrs:{"native-type":"submit","type":"primary"}},[_vm._v(" Validate inputs ")])],1)])],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }