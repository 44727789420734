
import AnimatedNumber from "@/components/AnimatedNumber.vue";
import { DashboardTotalQuantities } from "@/models/DashboardTotalQuantities";
import LineChart from "@/components/Charts/LineChart";
import { Component, Vue } from 'vue-property-decorator';
import DashboardHeader from "@/pages/Dashboard/Dashboard/HeaderChart"

@Component({
    components: {
        AnimatedNumber,
        LineChart,
        DashboardHeader
    }
})
export default class Dashboard extends Vue {
    protected totals: DashboardTotalQuantities = new DashboardTotalQuantities();

    created() {
        this.axios.get<DashboardTotalQuantities>('dashboard/total-quantities')
            .then(res => {
                if (res.data)
                    this.totals = new DashboardTotalQuantities(res.data);
            })
            .catch(err => {
                console.log('Error while loading total quantities in dashboard', { err, response: err.data?.response })
            })
    }

    emitEvent(eventName: string) {
        this.$root.$emit(eventName);
    }
};
