<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <h5 class="info-text">
        Let's start with the basic information (with validation)
      </h5>
      <div class="row justify-content-center">
        <div class="col-sm-4">
          <div class="picture-container">
            <el-upload
              class="picture"
              action="https://jsonplaceholder.typicode.com/posts/"
              :on-change="handlePreview"
              :auto-upload="false"
              :show-file-list="false"
            >
              <img
                v-if="imageUrl"
                :src="imageUrl"
                class="picture-src"
              >
            </el-upload>
            <h6 class="description">
              Choose Picture
            </h6>
          </div>
        </div>
        <div class="col-sm-6">
          <ValidationProvider
            v-slot="{ passed, failed }"
            name="firstName"
            rules="required"
          >
            <fg-input
              v-model="firstName"
              type="text"
              :error="
                failed
                  ? 'The First name field is required'
                  : null
              "
              :has-success="passed"
              class="form-control-lg"
              placeholder="First Name (required)"
              addon-left-icon="now-ui-icons users_circle-08"
            />
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ passed, failed }"
            name="lastName"
            rules="required"
          >
            <fg-input
              v-model="lastName"
              class="form-control-lg"
              :error="
                failed
                  ? 'The Last name field is required'
                  : null
              "
              :has-success="passed"
              placeholder="Last Name (required)"
              addon-left-icon="now-ui-icons text_caps-small"
            />
          </ValidationProvider>
        </div>
        <div class="col-12 col-sm-10 mt-3">
          <ValidationProvider
            v-slot="{ passed, failed }"
            name="email"
            rules="required|email"
          >
            <fg-input
              v-model="email"
              class="form-control-lg"
              :error="
                failed ? 'The Email field is required' : null
              "
              :has-success="passed"
              name="email"
              placeholder="Email (required)"
              addon-left-icon="now-ui-icons text_caps-small"
            />
          </ValidationProvider>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { Upload } from "element-ui";
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

extend("required", required);
extend("email", email);

export default {
    components: {
        [Upload.name]: Upload
    },
    data() {
        return {
            firstName: "",
            lastName: "",
            email: "",
            imageUrl: "img/default-avatar.png"
        };
    },
    methods: {
        handlePreview(file) {
            this.model.imageUrl = URL.createObjectURL(file.raw);
        },
        validate() {
            return this.$refs.form.validate().then(res => {
                this.$emit("on-validated", res);
                return res;
            });
        }
    }
};
</script>
<style></style>
