<template>
  <ValidationObserver
    v-slot="{ handleSubmit }"
    class="form-horizontal"
  >
    <form @submit.prevent="handleSubmit(submit)">
      <card>
        <h4
          slot="header"
          class="card-title"
        >
          Range Validation
        </h4>
        <div>
          <div class="row">
            <label
              class="col-sm-2 col-form-label"
            >Min Length</label>
            <div class="col-sm-7">
              <ValidationProvider
                v-slot="{ passed, failed }"
                name="minLength"
                rules="required|min:5"
              >
                <fg-input
                  v-model="minLength"
                  type="text"
                  :error="
                    failed
                      ? 'The Min Length field is required'
                      : null
                  "
                  :has-success="passed"
                  name="minLength"
                />
              </ValidationProvider>
            </div>
            <label
              class="col-sm-3 label-on-right"
            ><code>min="5"</code></label>
          </div>

          <div class="row">
            <label
              class="col-sm-2 col-form-label"
            >Max Length</label>
            <div class="col-sm-7">
              <ValidationProvider
                v-slot="{ passed, failed }"
                name="maxLength"
                rules="required|max:5"
              >
                <fg-input
                  v-model="maxLength"
                  type="text"
                  :error="
                    failed
                      ? 'The Max Length field is required'
                      : null
                  "
                  :has-success="passed"
                  name="maxLength"
                />
              </ValidationProvider>
            </div>
            <label
              class="col-sm-3 label-on-right"
            ><code>max="5"</code></label>
          </div>

          <div class="row">
            <label class="col-sm-2 col-form-label">Range</label>
            <div class="col-sm-7">
              <ValidationProvider
                v-slot="{ passed, failed }"
                name="range"
                rules="required|min:6|max:10"
              >
                <fg-input
                  v-model="range"
                  type="text"
                  :error="
                    failed
                      ? 'The Range field is required'
                      : null
                  "
                  :has-success="passed"
                  name="range"
                />
              </ValidationProvider>
            </div>
            <label
              class="col-sm-3 label-on-right"
            ><code>min_value="6", max_value="10"</code></label>
          </div>

          <div class="row">
            <label class="col-sm-2 col-form-label">Min Value</label>
            <div class="col-sm-7">
              <ValidationProvider
                v-slot="{ passed, failed }"
                name="minValue"
                rules="required|min_value:6"
              >
                <fg-input
                  v-model="minValue"
                  type="text"
                  :error="
                    failed
                      ? 'The Min Value field is required'
                      : null
                  "
                  :has-success="passed"
                  name="minValue"
                />
              </ValidationProvider>
            </div>
            <label
              class="col-sm-3 label-on-right"
            ><code>min_value="6"</code></label>
          </div>

          <div class="row">
            <label class="col-sm-2 col-form-label">Max Value</label>
            <div class="col-sm-7">
              <ValidationProvider
                v-slot="{ passed, failed }"
                name="maxValue"
                rules="required|max_value:6"
              >
                <fg-input
                  v-model="maxValue"
                  type="text"
                  :error="
                    failed
                      ? 'The Max Value field is required'
                      : null
                  "
                  :has-success="passed"
                  name="maxValue"
                />
              </ValidationProvider>
            </div>
            <label
              class="col-sm-3 label-on-right"
            ><code>max_value="6"</code></label>
          </div>
        </div>
        <div class="text-center">
          <n-button
            native-type="submit"
            type="primary"
          >
            Validate inputs
          </n-button>
        </div>
      </card>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from "vee-validate";
import {
    required,
    min,
    max,
    min_value,
    max_value
} from "vee-validate/dist/rules";

extend("required", required);
extend("min", min);
extend("max", max);
extend("min_value", min_value);
extend("max_value", max_value);

export default {
    data() {
        return {
            minLength: "",
            maxLength: "",
            range: "",
            minValue: "",
            maxValue: ""
        };
    },
    methods: {
        submit() {
            alert("Form has been submitted!");
        }
    }
};
</script>
<style></style>
