<template>
  <div>
    <div class="row d-flex justify-content-center">
      <div class="col-md-10 mr-auto ml-auto">
        <simple-wizard>
          <template #header>
            <h3 class="card-title">
              Build your profile
            </h3>
            <h3 class="description">
              This information will let us know more about you.
            </h3>
          </template>

          <wizard-tab :before-change="() => validateStep('step1')">
            <template #label>
              <i class="now-ui-icons users_circle-08" />
              About
            </template>
            <first-step
              ref="step1"
              @on-validated="onStepValidated"
            />
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step2')">
            <template #label>
              <i class="now-ui-icons ui-1_settings-gear-63" />
              Account
            </template>
            <second-step
              ref="step2"
              @on-validated="onStepValidated"
            />
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step3')">
            <template #label>
              <i class="now-ui-icons ui-1_email-85" />
              Address
            </template>
            <third-step
              ref="step3"
              @on-validated="wizardComplete"
            />
          </wizard-tab>
        </simple-wizard>
      </div>
    </div>
  </div>
</template>
<script>
import FirstStep from "./Wizard/FirstStep.vue";
import SecondStep from "./Wizard/SecondStep.vue";
import ThirdStep from "./Wizard/ThirdStep.vue";
import Swal from "sweetalert2";
import { SimpleWizard, WizardTab } from "src/components";

export default {
    components: {
        FirstStep,
        SecondStep,
        ThirdStep,
        SimpleWizard,
        WizardTab
    },
    data() {
        return {
            wizardModel: {}
        };
    },
    methods: {
        validateStep(ref) {
            return this.$refs[ref].validate();
        },
        onStepValidated(validated, model) {
            this.wizardModel = { ...this.wizardModel, ...model };
        },
        wizardComplete() {
            Swal.fire({
                title: "Good job!",
                text: "You clicked the finish button!",
                type: "success",
                confirmButtonClass: "btn btn-success",
                buttonsStyling: false
            });
        }
    }
};
</script>
