
  import {Component, Vue } from 'vue-property-decorator'; 
  import moment from 'moment';
  import HeaderChart from "@/pages/Dashboard/Dashboard/HeaderChart"
  import { DashboardHistoryRecord } from "@/models/DashboardHistoryRecord";

  @Component({
    name: "DashboardHeader",
    components: {
        HeaderChart
    }
  })
  export default class DashboardHeader extends Vue {
      private chartData:Array<number> = [];
      private chartLabels:Array<string> = [];
      private chartTitle = 'Created Scripts By Day'
      
      created(){
        this.loadChartData('opened-rxs-history');
      }

      mounted(){
        this.$root.$on('totalScriptsTodayClicked', () => {
          this.chartTitle = 'Created Scripts By Day';
          this.loadChartData('opened-rxs-history');
        })
        
        this.$root.$on('totalTodayRevenueClicked', () => {
          this.chartTitle = 'Total Revenue By Day';
          this.loadChartData('revenue-history');
        })
        
        this.$root.$on('totalOpenOrdersClicked', () => {
          this.chartTitle = 'Created Orders By Day';
          this.loadChartData('opened-orders-history');
        })
        
        this.$root.$on('totalOrdersShippedTodayClicked', () => {
          this.chartTitle = 'Shipped Orders By Day';
          this.loadChartData('shipped-orders-history');
        })
      }

      loadChartData(endpoint:string){
        
        this.axios.get<Array<DashboardHistoryRecord>>('dashboard/'+endpoint)
        .then(res => {
          if(res.data){
            this.chartLabels = res.data.map(p => moment(p.date).format('MMM-DD'));
            this.chartData = res.data.map(p => p.quantity);
          }
        })
        .catch(err => {
          console.log('Error while loading total quantities in dashboard', {err, response: err.data?.response})
        })

      }
  }
