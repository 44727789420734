<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <card no-footer-line>
        <div slot="header">
          <h4 class="card-title">
            Register Form
          </h4>
        </div>
        <div>
          <ValidationProvider
            v-slot="{ passed, failed }"
            name="email"
            rules="required|email"
          >
            <fg-input
              v-model="email"
              type="email"
              :error="
                failed ? 'The Email field is required' : null
              "
              :has-success="passed"
              label="Email address"
              name="email"
            />
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ passed, failed }"
            vid="confirmation"
            rules="required"
          >
            <fg-input
              v-model="password"
              type="password"
              :error="
                failed ? 'The Password field is required' : null
              "
              :has-success="passed"
              name="password"
              label="Password"
            />
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ passed, failed }"
            name="password"
            rules="required|confirmed:confirmation"
          >
            <fg-input
              v-model="confirmation"
              type="password"
              :error="
                failed ? 'The Password does not match' : null
              "
              :has-success="passed"
              name="confirmation"
              label="Confirm Password"
            />
          </ValidationProvider>
          <div class="category form-category">
            * Required fields
          </div>
        </div>
        <div
          slot="footer"
          class="text-right"
        >
          <checkbox
            v-model="subscribe"
            class="pull-left"
            name="subscribe"
          >
            Subscribe to newsletter
          </checkbox>

          <n-button
            native-type="submit"
            type="primary"
          >
            Register
          </n-button>
        </div>
      </card>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from "vee-validate";
import { required, email, confirmed } from "vee-validate/dist/rules";

extend("email", email);
extend("required", required);
extend("confirmed", confirmed);

import { Checkbox } from "src/components/index";

export default {
    components: {
        Checkbox
    },
    data() {
        return {
            email: "",
            password: "",
            confirmation: "",
            subscribe: false
        };
    },
    methods: {
        submit() {
            alert("Form has been submitted!");
        }
    }
};
</script>
<style></style>
